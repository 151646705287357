<template>
    <div>
        <h1>PUBLIC OFFER AGREEMENT</h1>
        <p>
            {{ FullName }}<br>
            {{ Ogrn }}<br>
            {{ Kpp }}<br>
            {{ Address }}<br>
            {{ FullName }}<br>
            {{ ManagerPosition }}<br>
            {{ ManagerName }}<br>
            {{ Bic }}<br>
            {{ BankAccount }}<br>
        </p>
        <b-loading :is-full-page="true" :active.sync="IsLoading" :can-cancel="false" />
    </div>
</template>

<script>
import { mapState } from "vuex"
import LoadingState from "@/mixins/LoadingState"
import { COMPANY_INFO_REQUEST } from "@/store/actions/contract"

export default {
    name: "ContractUsaAgent",
    mixins: [LoadingState],
    data() {
        return {
            IsLoading: true
        }
    },
    computed: mapState({
        FullName: (state) => state.Contract.Company.FullName,
        Ogrn: (state) => state.Contract.Company.Ogrn,
        Kpp: (state) => state.Contract.Company.Kpp,
        Address: (state) => state.Contract.Company.Address,
        ManagerPosition: (state) => state.Contract.Company.ManagerPosition,
        ManagerName: (state) => state.Contract.Company.ManagerName,
        Bic: (state) => state.Contract.Company.Bic,
        BankAccount: (state) => state.Contract.Company.BankAccount
    }),
    mounted() {
        if (!this.$store.getters.IS_COMPANY_CREATED) {
            this.$store.dispatch(COMPANY_INFO_REQUEST)
                .then(() => {
                    if (!this.$store.getters.IS_COMPANY_CREATED) this.$router.push({ name: "Contract" })
                })
                .finally(() => this.switchLoading())
        }
        else this.switchLoading()
    },
    metaInfo() {
        return {
            title: "PUBLIC OFFER AGREEMENT"
        }
    }
}
</script>

<style>
    html,
    body {
        background-color: white;
        padding: 20px;
    }
    #app {
        padding: 0;
    }
    h1,
    h2 {
        text-align: center;
        font-weight: bold;
    }

    h1 {
        font-size:22px;
    }
    h2 {
        font-size:20px;
        text-transform: uppercase;
    }
    h3 {
        font-size:18px;
        text-align: right;
        font-weight: bold;
    }
    table {
        margin: auto;
    }
    p {
        text-align: justify;
        padding: 0px;
    }
    .loading-overlay .loading-background {
        background-color: white;
    }
</style>
